
import { defineComponent } from "@vue/runtime-core"

const Redirect = defineComponent({
  created() {
    const { params, query } = this.$route
    const { path } = params
    this.$router.replace({ path: '/' + path, query })
  },
  render: function(h: any) {
    return h() // avoid warning message
  }
})

export default Redirect
